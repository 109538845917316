import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker = _resolveComponent("datepicker")!

  return (_openBlock(), _createBlock(_component_datepicker, {
    enableTimePicker: false,
    startTime: _ctx.startTime,
    modelValue: _ctx.date,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event)),
      _ctx.onChange
    ],
    minDate: _ctx.minDate,
    maxDate: _ctx.maxDate,
    textInput: "",
    utc: "preserve",
    class: "ab-input"
  }, null, 8, ["startTime", "modelValue", "minDate", "maxDate", "onUpdate:modelValue"]))
}