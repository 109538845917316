
import { defineComponent, ref, watch } from "vue";
import Datepicker from "@vuepic/vue-datepicker";

export default defineComponent({
  props: ["modelValue", "maxDate", "minDate"],
  emits: ["update:modelValue", "valueChanged"],
  components: {
    Datepicker,
  },
  methods: {
    onChange(modelData) {
      //2022-11-05T23:00:00.000Z
      //TODO fix before https://github.com/Vuepic/vue-datepicker/pull/184
      let newDate = new Date(modelData);
      if (newDate.getUTCHours() == 23) {
        newDate.setHours(newDate.getHours() + 1);
        const month = (newDate.getUTCMonth() + 1).toString().padStart(2, "0");
        const day = newDate.getUTCDate().toString().padStart(2, "0");
        modelData = `${newDate.getFullYear()}-${month}-${day}T${"00"}:${"00"}:00.000Z`;
        this.date = modelData;
      }
      //this.date.value = newVal;
      this.$emit("update:modelValue", modelData);
      this.$emit("valueChanged", modelData);
    },
  },
  setup(props) {
    let date = ref(props.modelValue);
    const startTime = ref({ hours: 0, minutes: 0 });
    watch(
      () => props.modelValue,
      () => {
        if (props.modelValue) {
          date.value = props.modelValue;
        } else {
          date.value = null;
        }
      }
    );

    return { date, startTime };
  },
});
