
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  watch,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Multiselect from "@vueform/multiselect";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import IntegerInput from "@/components/ABilling/IntegerInput.vue";
import DocumentsList from "@/modules/documents/DocumentList.vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, maxLength } from "@vuelidate/validators";
import { useRoute, useRouter } from "vue-router";
import { searchByName } from "@/api/patient.api";
import {
  addPaymentPlan,
  getPaymentPlan,
  updatePaymentPlan,
} from "@/api/paymentPlan.api";
import { getPanel } from "@/api/panel.api";
import { getUTCnow, roundNumber } from "@/utility";
import moment from "moment";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DateComponent from "@/components/ABilling/DateComponent.vue";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface RevenueCode {
  code: string;
  description: string;
  chargePerUnit: number;
}

export interface Data {
  postingInProgress: boolean;
  isDirty: boolean;
  isLoaded: boolean;
  paymentPlan?: any;
  previousPaymentPlan?: any;
  createNewMode: boolean;
  dict: {
    facilities: [];
    installmentStatuses: [];
    paymentPlanCollectionTypes: [];
    paymentFrequencyList: any[];
    installmentCriteriaList: any[];
  };
  calculator: {
    installmentStartDate?: any;
    paymentFrequency?: any;
    paymentFrequencyType?: any;
    installmentCriteria?: any;
    amountPerPayment?: any;
    totalNumberOfPayments?: any;
    installmentEndDate?: any;
  };
}

export default defineComponent({
  name: "PaymentPlanComponent",
  components: {
    Multiselect,
    DecimalInput,
    IntegerInput,
    DocumentsList,
    DateComponent,
    DateFloatComponent,
  },
  props: ["paymentPlanId"],
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";

      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    const store = useStore();
    let organizationId = ref<string | null>("");
    const serviceHistoryPayment = ref(null);
    const documents = ref(null);

    const router = useRouter();
    const route = useRoute();
    let data = reactive<Data>({
      postingInProgress: false,
      isDirty: false,
      isLoaded: false,
      paymentPlan: undefined,
      createNewMode: true,
      dict: {
        facilities: [],
        paymentPlanCollectionTypes: [],
        installmentStatuses: [],
        paymentFrequencyList: [
          { name: "Days", code: 0 },
          { name: "Weeks", code: 1 },
          { name: "Month", code: 2 },
        ],
        installmentCriteriaList: [
          { name: "Amount Per Payment", code: 0 },
          { name: "Total Number of Payments", code: 1 },
          { name: "Installment End Date", code: 2 },
        ],
      },
      calculator: {
        installmentStartDate: null,
        paymentFrequency: null,
        paymentFrequencyType: null,
        installmentCriteria: null,
        amountPerPayment: 0,
        totalNumberOfPayments: null,
        installmentEndDate: null,
      },
    });

    watch(
      () => data.paymentPlan,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.previousPaymentPlan &&
          JSON.stringify(currentValue) !=
            JSON.stringify(data.previousPaymentPlan)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    const breadcrumbPath = decodeURIComponent(
      String(route.query?.breadcrumb ?? "back")
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      const panel = await getPanel();
      setCurrentPageBreadcrumbs("Payment Plans", [
        { buttonTitle: "Back", path: breadcrumbPath },
      ]);
      data.dict.facilities = panel.facilities;
      data.dict.installmentStatuses = store.getters.allInstallmetStatuses;
      data.dict.paymentPlanCollectionTypes =
        store.getters.allPaymentPlanCollectionTypes;

      if (props.paymentPlanId) {
        data.paymentPlan = await getPaymentPlan(props.paymentPlanId);
      } else {
        data.paymentPlan = {
          title: "Payment Plan-" + moment(getUTCnow()).format("MM-DD-YYYY"),
          paymentInstallments: [],
          amount: null,
        };
      }
      data.previousPaymentPlan = JSON.parse(JSON.stringify(data.paymentPlan));

      data.isLoaded = true;
    });

    function addPaymentInstallment() {
      let installment = {
        dueDate: getUTCnow().toISOString(),
        title: "",
        status: 1,
        amount: 0,
      };
      data.paymentPlan.paymentInstallments.push(installment);
    }

    function removePaymentInstallment(installment) {
      data.paymentPlan.paymentInstallments.splice(
        data.paymentPlan.paymentInstallments.indexOf(installment),
        1
      );
    }

    const paymentPlanRules = {
      paymentPlan: {
        amount: { required: helpers.withMessage("Required", required) },
        title: {
          required: helpers.withMessage("Required", required),
          maxLength: maxLength(75),
        },
        facilityId: { required: helpers.withMessage("Required", required) },
        description: { maxLength: maxLength(200) },
        collectionType: { required: helpers.withMessage("Required", required) },
        patientId: { required: helpers.withMessage("Required", required) },
        paymentInstallments: {
          required: helpers.withMessage("Required", required),
          minLength: minLength(1),
          $each: helpers.forEach({
            amount: {
              required: helpers.withMessage("Required", required),
            },
            title: {
              maxLength: maxLength(150),
            },
            dueDate: {
              required: helpers.withMessage("Required", required),
            },
          }),
        },
      },
    };

    let v$ = useVuelidate(paymentPlanRules, data as never) as any;

    async function searchPatients(text) {
      const patients = await searchByName({ search: text });
      return patients;
    }

    function patientSelected(patientId) {
      const f = 5;
    }

    async function add() {
      const result = await v$.value.$validate();
      if (!result) return;
      const res = await addPaymentPlan(data.paymentPlan);
      data.paymentPlan.id = res;
      await nextTick();
      data.previousPaymentPlan = JSON.parse(JSON.stringify(data.paymentPlan));
      data.isDirty = false;

      const doc = documents.value as any;
      await doc.uploadFiles();
      router.push({
        path: "/Payments/PaymentPlanList",
      });
    }

    async function update() {
      const result = await v$.value.$validate();
      if (!result) return;
      await updatePaymentPlan(data.paymentPlan);
      data.previousPaymentPlan = JSON.parse(JSON.stringify(data.paymentPlan));
      data.isDirty = false;

      const doc = documents.value as any;
      await doc.uploadFiles();
      router.push({
        path: "/Payments/PaymentPlanList",
      });
    }

    function installmentsTotal() {
      return roundNumber(
        data.paymentPlan.paymentInstallments.reduce(
          (partialSum, a) => partialSum + a.amount,
          0
        ),
        2
      );
    }

    function isGenerateEnabled() {
      if (
        !data.paymentPlan.amount ||
        !data.calculator.installmentStartDate ||
        !data.calculator.paymentFrequency ||
        !data.calculator.paymentFrequencyType == null ||
        data.calculator.installmentCriteria == null ||
        data.calculator.paymentFrequencyType == null
      )
        return false;
      if (
        data.calculator.installmentCriteria == 0 &&
        (!data.calculator.amountPerPayment ||
          data.calculator.amountPerPayment == 0)
      )
        return false;
      if (
        data.calculator.installmentCriteria == 1 &&
        !data.calculator.totalNumberOfPayments
      )
        return false;
      if (
        data.calculator.installmentCriteria == 2 &&
        !data.calculator.installmentEndDate
      )
        return false;

      return true;
    }

    function generate() {
      data.paymentPlan.paymentInstallments = [];

      let amount = data.paymentPlan.amount;
      let amountPerPayment = 0;
      let stepsCount = 0;

      if (data.calculator.installmentCriteria == 0) {
        amountPerPayment = data.calculator.amountPerPayment;
        stepsCount = Math.ceil(amount / data.calculator.amountPerPayment);
      }
      if (data.calculator.installmentCriteria == 1) {
        amountPerPayment = roundNumber(
          amount / data.calculator.totalNumberOfPayments,
          2
        );
        stepsCount = data.calculator.totalNumberOfPayments;
      }
      if (data.calculator.installmentCriteria == 2) {
        const startDate = data.calculator.installmentStartDate;
        const endDate = data.calculator.installmentEndDate;
        let playmentsCount = 0;
        let diff = 0;
        if (data.calculator.paymentFrequencyType == 0)
          diff = moment(endDate).diff(moment(startDate), "day");
        if (data.calculator.paymentFrequencyType == 1)
          diff = moment(endDate).diff(moment(startDate), "week");
        if (data.calculator.paymentFrequencyType == 2)
          diff = moment(endDate).diff(moment(startDate), "month");
        diff = diff + data.calculator.paymentFrequency;
        playmentsCount = Math.floor(diff / data.calculator.paymentFrequency);

        amountPerPayment = roundNumber(amount / playmentsCount, 2);
        stepsCount = playmentsCount;
      }

      for (let i = 0; i < stepsCount; i++) {
        let instAmount = amountPerPayment;
        if (instAmount > amount) instAmount = amount;
        if (amount - instAmount < 1 && amount - instAmount > 0)
          instAmount = amount;
        amount -= instAmount;
        let installment = {
          dueDate: getUTCnow().toISOString(),
          title: "",
          amount: instAmount,
          status: 1,
        };
        data.paymentPlan.paymentInstallments.push(installment);
      }
      for (let i = 0; i < data.paymentPlan.paymentInstallments.length; i++) {
        const installment = data.paymentPlan.paymentInstallments[i];
        installment.title =
          "Installment " +
          (i + 1) +
          " of " +
          data.paymentPlan.paymentInstallments.length +
          " " +
          data.paymentPlan.title;
        if (data.calculator.paymentFrequencyType == 0)
          installment.dueDate = moment(
            data.calculator.installmentStartDate
          ).add(data.calculator.paymentFrequency * i, "days");
        if (data.calculator.paymentFrequencyType == 1)
          installment.dueDate = moment(
            data.calculator.installmentStartDate
          ).add(data.calculator.paymentFrequency * i, "week");
        if (data.calculator.paymentFrequencyType == 2)
          installment.dueDate = moment(
            data.calculator.installmentStartDate
          ).add(data.calculator.paymentFrequency * i, "month");
      }
    }

    async function cancel() {
      router.push({
        path: "/Payments/PaymentPlanList",
      });
    }

    function isAnyPayment() {
      if (!data.paymentPlan) return false;
      return data.paymentPlan.paymentInstallments.find((inst) => {
        return inst.actualPaid && inst.actualPaid > 0;
      });
    }

    return {
      organizationId,
      add,
      cancel,
      data,
      documents,
      generate,
      update,
      searchPatients,
      isAnyPayment,
      isGenerateEnabled,
      patientSelected,
      installmentsTotal,
      serviceHistoryPayment,
      addPaymentInstallment,
      removePaymentInstallment,
      v$,
    };
  },
});
